import React from "react";
import Artykul from "../components/artykul";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import obrazekArtykulu from "../images/system-crm-w-zarzadzaniu-vlog.png";
import Lightbox from "../components/lightBox";
import ResponsiveEmbed from "react-responsive-embed";
import { Link } from "gatsby";
import * as styles from "./index.module.css";


export const query = graphql`
  {
    zdjecie1: file(relativePath: { eq: "szanse-sprzedazy-crm.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    zdjecie2: file(
      relativePath: {
        eq: "dynamika-satysfakcji-utrzymanie-wspolpracy-z-klientami-min.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    zdjecie3: file(relativePath: { eq: "crm-karta-zlecenia.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;

const Zarzadzaniekampaniamicrm = ({ data }) => {
  return (
    <Artykul
      title="Zalety elastycznego systemu CRM"
      keywords={["elastyczny crm"]}
      articleImage={obrazekArtykulu}
      articleImageAlt="Zalety elastycznego systemu CRM"
      metaTitle="Zalety elastycznego systemu CRM"
      metaDescription="Elastyczny CRM może dopasować się do potrzeb każdej firmy. ✅ Jak poznać elastyczny CRM? • Plusy elastycznego CRM
• Video 2020"
    >
      <br />
      <p>
        Elastyczny CRM to rozwiązanie, które posłuży firmie o wiele dłużej i
        efektywniej niż system ubogi w opcje konfiguracyjne. Dlaczego tak jest?
        Po czym rozpoznać, że dany CRM jest elastyczny? Na co zwrócić uwagę
        podczas wyboru rozwiązania tego typu? O tym wszystkim dowiesz się z
        poniższego materiału.
      </p>
      <br />
      <ResponsiveEmbed
        src="https://www.youtube.com/embed/HV3OYUaHOCk"
        allowFullScreen
        ratio="16:9"
      />
      <br />
      <h2>Czym jest elastyczny CRM?</h2>
      <p>
        Elastyczny CRM to system, który bez pomocy dostawcy możemy dostosować do
        potrzeb naszej firmy. Jest to narzędzie, które w dużej mierze
        odzwierciedla procesy biznesowe w firmie. Kluczowa jest tutaj
        niezależność użytkownika końcowego w konfigurowaniu oprogramowania.
      </p>

      <p>
        Niemal każde rozwiązanie tego typu oferowane na rynku może być w jakimś
        stopniu dopasowane do potrzeb danego biznesu. Ma to miejsce w
        szczególnie szerokim zakresie w przypadku systemów dedykowanych. Czy
        takie oprogramowanie można jednak od razu nazwać elastycznym? Jesteśmy
        zdania, że nie.
      </p>

      <p>
        System CRM jest elastyczny, gdy nie potrzebujemy ingerencji jego
        dostawcy w celu wprowadzenia doń prostych zmian konfiguracyjnych.
        Oczywiście poważniejsze zmiany, ingerujące w rdzeń systemu, zawsze będą
        wymagały interwencji dostawcy systemu. A pomniejsze modyfikacje systemu
        zawsze okazują się niezbędne - procesy biznesowe ulegają bowiem ciągłej
        ewolucji, a dobrze działający CRM powinien je wiernie odwzorowywać.
      </p>

      <p>
        Gotowe systemy, czyli takie, które nie są dedykowane konkretnemu
        klientowi, również mogą być elastyczne. Wszystko zależy od tego, jak
        wiele obszarów można w nich edytować i dopasowywać do zmieniających się
        potrzeb przedsiębiorstwa.
      </p>
      <br />
      <h2>Elastyczność CRM - wada czy zaleta?</h2>
      <p>
        Zachowanie równowagi między elastycznością systemu a przystępnością
        pracy na nim jest trudną sztuką. Trzeba bowiem przyznać, że elastyczny
        CRM, jedyny, który daje Twojej firmie możliwość odzwierciedlenia
        ewoluujących procesów biznesowych, może być nieco trudniejszy do
        skonfigurowania.
      </p>
<section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz bezpłatnie wypróbować elastyczny system CRM w Twojej firmie?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas, aby zyskać dostęp do bezpłatnej 30-dniowej wersji systemu CRM

</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować i cieszyć się elastycznością
            </Link>
          </div>
          </div>
    </section>
      <p>
        Czy powinieneś się jednak tym martwić? Konfiguracja systemu to nie jest
        przecież coś, co powtarzamy każdego tygodnia. Wykonuje się ją tylko
        podczas wdrożenia oraz w razie zmian w procesach zachodzących w firmie.
        Zresztą w tym drugim przypadku objawia się główna zaleta elastycznego
        CRM - możliwość jego adaptacji do nowych sposobów działania
        przedsiębiorstwa.
      </p>
      <br />
      <h2>Czy branżowe rozwiązania są dobrą inwestycją?</h2>
      <p>
        Ograniczenie się do szukania jedynie branżowych rozwiązań może wydłużyć
        proces rozglądania się za nowym CRM (ponieważ w danym segmencie może być
        ich po prostu mało - lub wcale), oraz wiąże się zazwyczaj z wyższymi
        kosztami.
      </p>

      <p>
        Tymczasem odpowiednio elastyczne rozwiązania da się bez problemu
        zastosować w bardzo wielu różnorodnie działających branżach. Dzięki ich
        wysokiej konfigurowalności można z powodzeniem odzwierciedlić niemal
        każdy proces - i to do tego stopnia, że różnica między systemem
        branżowym a elastycznym nie będzie wyczuwalna.
      </p>
      <br />
      <h2>Co w dobrym CRM powinno być konfigurowalne przez użytkownika?</h2>
      <br />
      <h3>Szanse sprzedaży</h3>
      <p>
        Tutaj elastyczność systemu jest chyba najważniejsza. CRM powinien
        pozwalać na dowolną konfigurację kampanii sprzedażowych, począwszy od
        ich nazwy, przez liczbę etapów, celów aż po opisujące daną kampanię
        atrybuty. Rzecz jasna, niezbędna jest również opcja prowadzenia wielu
        kampanii na raz - bowiem procesów sprzedażowych jest zazwyczaj w firmie
        wiele i rzadko kiedy da się je zamknąć w jednej ścieżce sprzedaży.
      </p>
      <br />
      <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.zdjecie1]}
        alts={["Widok szans sprzedaży w systemie CRM."]}
      />
      <br />
      <p>
        W kluczowym obszarze, jakim są szanse sprzedaży, musisz mieć pewność, że
        to system dopasuje się do Ciebie, a nie na odwrót.
      </p>
      <br />
      <h3>Baza kontrahentów</h3>
      <p>
        Wszyscy wiedzą, że w CRM zbiera i organizuje się dane kontrahentów.
        Każda firma może jednak potrzebować różnych danych o swoich klientach.
        Niektóre będą oczywiście wspólne, takie jak NIP, adres lub dane
        kontaktowe, poza tym jednak w każdym przedsiębiorstwie mogą wystąpić
        inne potrzeby.
      </p>

      <p>
        Z drugiej strony możliwość tworzenia atrybutów kontrahenta oferuje duże
        możliwości podczas segmentacji i wyszukiwania klientów. Istnieje wiele
        przykładów takich atrybutów - status współpracy, skala, branża i tym
        podobne.
      </p>
      <br />
      <h3>Pulpit pracy</h3>
      <p>
        Pulpit to obszar, który ukazuje się po zalogowaniu użytkownika do CRM.
        Nie każdy system posiada takie rozwiązanie - pomimo tego, że okazuje się
        ono bardzo przydatne w codziennej pracy. Dzięki niemu wszystkie te
        miejsca, które często odwiedzamy w systemie, możemy mieć pod ręką.
        Konfigurowalny pulpit jest więc sposobem na zaoszczędzenie znacznej
        ilości czasu.
      </p>
      <br />
      <h3>Zadania, zdarzenia i kalendarz</h3>
      <p>
        W CRM planujesz zadania i rejestrujesz zdarzenia (tj. ukończone
        zadania). W ten sposób w systemie powstaje historia interakcji z
        klientem, która jest świetnym narzędziem do poprawiania relacji z
        kontrahentami oraz optymalizowania procesów sprzedażowych. Dzięki
        elastycznemu CRM możesz decydować, jakie rodzaje zadań i zdarzeń są
        dostępne dla Twoich pracowników. Możesz usunąć domyślne zadania, jeżeli
        Twoja firma ich nie potrzebuje i w to miejsce wprowadzić własne.
      </p>

      <p>
        Typy zadań i zdarzeń, takie jak działania pracowników, można
        wykorzystywać do analiz. Dzięki raportom możesz sprawdzić, ile zadań
        określonego typu zostało wykonanych. Szybko przeanalizujesz szczegóły,
        ponieważ nie ma potrzeby przeszukiwania całej bazy danych. Wystarczy
        wskazać wybraną osobę i już można przejrzeć, co robiła danego dnia.
      </p>
      <br />
      <h3>Projekty</h3>
      <p>
        System CRM może wspierać firmę również w obszarze zarządzania projektami
        oraz zleceniami. Jako menedżer dostajesz w ten sposób szybką i konkretną
        informację o, na przykład, etapie wykonania konkretnego projektu lub
        zadania. Pracownicy natomiast otrzymują szczegółową listę zadań, które
        mają wykonać, wraz z opisem tego, czego się od nich oczekuje. Żadne
        zadanie nie znika i nie jest pomijane. Mówiliśmy o tym więcej w filmie,
        który można znaleźć{" "}
        <Link to="/zarzadzanie-zespolem-marketingu-crm">
          <strong>tutaj</strong>
        </Link>
        .
      </p>
      <br />
      <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.zdjecie3]}
        alts={["Karta zlecenia w systemie CRM."]}
      />
      <br />
      <p>
        W tym obszarze elastyczny CRM umożliwia edycję typów projektów czy
        zleceń. Ponadto daje możliwość utworzenia wielu różnorodnych typów
        projektów oraz prowadzenia wielu na raz - dzięki temu możesz
        przygotowywać osobne projekty dla każdego działu firmy. Edytowalna
        powinna być też sama karta zlecenia, bowiem w każdej firmie mogą być
        potrzebne inne pola i informacje.
      </p>
      <br />
      <h3>Produkty i tworzenie ofert</h3>
      <p>
        Głównym przeznaczeniem bazy produktów w CRM jest zapewnienie wszystkim
        pracownikom dostępu do tych samych i aktualnych danych, takich jak opis
        produktu, cechy produktu, cena i dostępność w magazynie. Dzięki tej
        funkcjonalności możesz także szybko tworzyć oferty bez konieczności
        przepisywania informacji. W tym obszarze elastyczny CRM pozwala na
        edycję listy produktów, operowanie na wielu poziomach cenowych oraz
        decydowaniu, jakimi atrybutami i informacjami ma być opisany produkt.
      </p>
      <br />
      <h3>Dokumenty</h3>
      <p>
        Teraz kilka słów na temat szablonów dokumentów. Jeśli w Twoim
        przedsiębiorstwie pracuje osoba posiadająca umiejętności techniczne do
        tworzenia lub edytowania szablonów (na przykład ofert lub faktur), to
        bez problemu może w elastycznym systemie CRM dostosowywać formę
        dokumentów do potrzeb firmy. Może na przykład dodać logo
        przedsiębiorstwa, zdjęcia lub nowe pola na informacje.
      </p>
      <br />
      <h3>Uprawnienia i profile</h3>
      <p>
        Uprawnienia to niezwykle istotny obszar każdego systemu CRM, bowiem
        odpowiada on za bezpieczeństwo danych należących do firmy. Poza tym
        wpływa on na przejrzystość i czytelność interfejsu.
      </p>

      <p>
        Dzięki elastycznemu CRM możesz zdecydować, kto powinien widzieć
        określone części systemu, a nawet określone pola. Nie każdy z
        pracowników powinien cieszyć się nieograniczonym dostępem do wszystkich
        danych należących do firmy. CRM musi zapewniać bardzo kompleksowy system
        autoryzacji, który umożliwia włączenie lub wyłączenie widoczności
        niektórych pól dla określonych profili pracowników.
      </p>

      <p>
        Profile są czymś w rodzaju grup, do których przypisuje się pracowników
        firmy. Jest to tożsame z nadaniem im pakietu uprawnień, który został
        zdefiniowany dla danej grupy. Najczęściej spotykana klasyfikacja
        pracowników to: administrator, kierownik i handlowiec. Oczywiście,
        profili można stworzyć więcej - na przykład dla stażystów, z minimalną
        ilością informacji.
      </p>
      <br />
      <h3>Raporty</h3>
      <p>
        Systemy CRM wyposażone są często w standardowe raporty. Te elastyczne
        umożliwiają edycję istniejących i tworzenie własnych. W naszym
        oprogramowaniu raporty można w dużym stopniu konfigurować. Dla
        przykładu, z raportu ‘sprzedaż według pozycji’ można utworzyć kolejne:
      </p>
      <p>
        <ul>
          <li>sprzedaż według kategorii produktów,</li>
          <li>sprzedaż według kontrahentów,</li>
          <li>sprzedaż według pracowników.</li>
        </ul>
      </p>
      <br />
      <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.zdjecie2]}
        alts={["Raport ukazujący aktywność w obszarze sprzedaży pośredniej."]}
      />
      <br />
      <p>
        Przygotowane raporty można przypisać do pulpitu - żeby zawsze były pod
        ręką.
      </p>
      <br />
      <h2>Na zakończenie</h2>
      <p>
        Oczywiście listę konfigurowalnych elementów systemu CRM można by jeszcze
        ciągnąć długo, poprzestaniemy jednak na powyższych przykładach. Naszym
        celem było bowiem jedynie wskazanie najważniejszych elementów systemu,
        które powinny być edytowalne. To powinno wystarczyć do tego, by przy
        wyborze rozwiązania tego typu łatwo zidentyfikować system elastyczny.
      </p>
<section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz bezpłatnie wypróbować elastyczny system CRM w Twojej firmie?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas, aby zyskać dostęp do bezpłatnej 30-dniowej wersji systemu CRM

</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować i cieszyć się elastycznością
            </Link>
          </div>
          </div>
    </section>
      <p>
        <ul>
          <li>
            <Link to="/wdrazanie-kultury-pracy-CRM">
              Wdrażanie kultury pracy z CRM w organizacji
            </Link>
          </li>
          <li>
            <Link to="/zarzadzanie-zespolem-marketingu-crm">
              Zarządzanie pracą zespołu marketingu w systemie CRM
            </Link>
          </li>
          <li>
            <Link to="/zarzadzanie-zespolem-rozproszonym">
              Zarządzanie rozproszonym zespołem sprzedażowym w czasie kryzysu
            </Link>
          </li>
        </ul>
      </p>
    </Artykul>
  );
};

export default Zarzadzaniekampaniamicrm;
